<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header notice">
            <div class="flexH width">
                <a class="icon iconButton third" @click.prevent="$router.push('/record')">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">{{ entryType }}紀錄詳情</span>
            <div class="flexH width right">                
                &nbsp;
                            
            </div>
        </section>
        <!-- Main -->
        <section class="main p-0 p-40px">
            
            <div class=" flexV width padding bg-white mt-4 mb-4">
                <div class="board-pad" v-if="transaction">
                    <div class="flex flex-col uide-bottom-border py-4 gap-1">
                        <div class="cNeutral500">{{ entryType }}時間</div>
                        <div class="cNeutral900 text-lg">{{tsToDatetime(transaction.transactionTs)}}</div>
                    </div>     
                    <!-- <div v-if="transaction.transactionType" class="flex flex-col uide-bottom-border py-4 gap-1">
                        <div class="cNeutral500">雲林幣到期日</div>
                        <div class="cNeutral900 text-lg"></div>
                    </div>   -->
                    <div class="flex flex-col uide-bottom-border py-4 gap-1">
                        <div class="cNeutral500">雲林幣{{ entryType2 }}數量</div>
                        <div class="cNeutral900 text-lg">{{ transaction.pointAmount }}枚</div>
                    </div>  
                    <div class="flex flex-col uide-bottom-border py-4 gap-1">
                        <div class="cNeutral500">
                            {{ entryType2 }}說明</div>
                        <div class="cNeutral900 text-lg">{{transaction.note}}</div>
                    </div>  
                </div>
              
            </div>
        </section>
    </div>
</template>
<script>
import { db } from "../db.js";
import moment from "moment-timezone";
import { mapState, mapGetters } from "vuex";


export default {
    name: "NotificationSetting",
    components: {

    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            transactions: {},
            // notifications: [],
            latestReadAt: 0,
            isSwitch1On:true,
            isSwitch2On:true,
            isSwitch3On:true,
            isSwitch4On:true,
            isSwitch5On:true,
        };
    },
    
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                // window.location.reload();
                next("/home");
            }
        });
    },
    mounted() {
        console.log("mounted!");
        this.getUserPoints()
        .then((getUserPointsRes) => {
            console.log('getUserPointsRes: ', getUserPointsRes);
            this.$store.commit('updateUserPoints', getUserPointsRes);
            return this.getTransactions();
        })
        .then((getTransactionsRes) => {
            this.transactions = JSON.parse(JSON.stringify(getTransactionsRes));
            this.originTransactions = JSON.parse(
                JSON.stringify(getTransactionsRes)
            );
        })
        .catch((err) => {
            console.log('err: ', err);
            this.router.push('/error');
        });
        // this.onSnapShot();

        //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
        // this.getLatestReadTime()
        // .then(() => {
        //     this.onSnapShot();
        // });

        setTimeout(() => {
            console.log('Notifications', this.notifications);
        }, 4000)
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters({
            userPoints: 'getTyUserPoints',
        }),
        entryType() {
            return this.transaction?.entryType === 'D' ? '獲得' : '兌換'
        },
        entryType2() {
            return this.transaction?.entryType === 'D' ? '獲得' : '扣除'
        },
        transaction() {
          return this.transactions.records && this.transactions.records
            .filter(r => r.transactionId == this.$route.params.id) // 找到所有符合條件的記錄
            .reduce((acc, curr) => {
              Object.keys(curr).forEach(key => {
                if (key === 'pointAmount') {
                  acc[key] = (acc[key] || 0) + curr[key]
                } else {
                  acc[key] = curr[key];
                }
              });
              return acc;
            }, {}); // 初始化累加器為空物件
        },
        // ...mapGetters({
        //     notifications: 'noti/notifications'
        // }),
        notifications() {
            let ns = this.$store.getters['noti/notifications'];
            // fake data
            // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
            return Object.values(ns).sort((a, b) => {
                return b.ts - a.ts;
            });
        }
    },
    methods: {
        compare(a, b) {
            if (a.ts > b.ts) {
                return -1;
            } else if (a.ts == b.ts) {
                return 0;
            } else if (a.ts < b.ts) {
                return 1;
            }
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        onSnapShot() {
            let collection = db.collection(
                `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            );
            // doesn't use change to vuefire bind
            collection.onSnapshot((snapshot) => {
                this.notifications = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    this.notifications.push(
                        Object.assign({}, data, { docId: doc.id, unread: data.unread && (data.ts > this.latestReadAt) })
                    );
                });
                this.notifications.sort(this.compare);
                console.log("this.notifications: ", this.notifications)
                this.getLatestReadTime();
            });
        },
        getLatestReadTime() {
            return db.collection(`notifications/yulin/recipients/${this.user['userId']}/readTopics`)
            .doc(this.user.user.firestoreChannelId)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) return;
                this.latestReadAt = snapshot.data().latestReadAt;
            });
        },
        updateUnreads(_latestReadAt) {
            if (_latestReadAt)
                this.latestReadAt = _latestReadAt;
            this.notifications.forEach(noti => {
                if (noti.ts <= this.latestReadAt)
                    noti.unread = false;
            });
        },
        readItem(docId) {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let document = collection.doc(docId);
            // return db.runTransaction((transaction) => {
            //     return transaction.get(document).then((doc) => {
            //         transaction.update(document, {
            //             unread: false
            //         });
            //         return doc;
            //     });
            // });
            //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    member_id: this.userInfo.memberId,
                    uuid: this.user.uuid,
                    message_id: docId
                }
            }
            return this.$http(config);
        },
        readAll() {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let batch = db.batch();
            // collection
            //     .get()
            //     .then((querySnapshot) => {
            //         querySnapshot.forEach((doc) => {
            //             let docRef = collection.doc(doc.id);
            //             batch.update(docRef, {unread: false});
            //         });
            //         batch.commit()
            //             .then(() => {
            //                 console.log("Read All!");
            //             })
            //     })
            //     .catch((error) => {
            //         console.log("Error getting documents: ", error);
            //     });
            //NOTE: Howard's fix: use API to mark all read instead of writing Firestore directly.
            console.log('this.user', this.user)
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-topic-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    uuid: this.user['userId']
                }
            }
            this.updateUnreads(moment().unix());//Manually mark all current notifications "read".
            this.$store.commit('noti/setUnreadCount', 0);
            return this.$http(config);
        },
        getUserPoints() {
            var config = {
                method: 'get',
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
                headers: {},
            };

            return this.$http(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        callTransactionsAPI(start, end) {
            let endTime = end || Math.floor(Date.now() / 1000);
            let startTime = start || endTime - 1 * 60 * 60 * 24 * 30;

            var config = {
                method: 'get',
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points/${this.userPoints.pointCode}/ledgers?from_ts=${startTime}&to_ts=${endTime}`,
                headers: {},
            };
            return this.$http(config)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getTransactions(start, end) {

            return (
                this.callTransactionsAPI(start, end)
                // return this.dummyTransactions()
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log('err:', err);
                })
            );
        },
    }
};
</script>

